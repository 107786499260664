import React from "react"
import { graphql } from "gatsby"

import { Layout } from "../../components/layout"
import Seo from "../../components/seo"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import { MagLinks } from "../../components/MagLinks"
import { NextLink } from "../../components/NextLink"

const worksSingle = ({data}) => {
  const postArry = [...data.allMicrocmsWorks.edges]
  const currentNum = data.microcmsWorks.sortIndex
  const currentIndex = postArry[currentNum]
  const nextContains = currentIndex.next === null
  const roleArry = [...data.microcmsWorks.detail.role]

  return (
    <Layout>
      <Seo 
        title={data.microcmsWorks.title}
        description={`DESIGN STUDIO Kの制作実績である${data.microcmsWorks.title}ページです。`}
        pageOgp={`${data.microcmsWorks.eyecatch.url}?w=1200`}
      />
      <div className="eyecatch single">
        <div className="eyecatch__inner">
          <div className="eyecatch__innerTtl">
            <h1>
              <span>{data.microcmsWorks.title}</span>
            </h1>
            <span>
              <span>Launch / {data.microcmsWorks.launch}</span>
            </span>
          </div>
        </div>
      </div>
      <section className="single__container enterContents">
        <Controller>
          <Scene
            duration="300%"
            triggerHook="onEnter"
          >
            <Timeline>
              <figure className="single__containerFig">
                <Tween
                  position="0"
                  from={{
                    yPercent: -15,
                  }}
                  to={{
                      yPercent: 5,
                  }}
                >
                  <img src={data.microcmsWorks.eyecatch.url} alt={data.microcmsWorks.title} />
                </Tween>
              </figure>
            </Timeline>
          </Scene>
        </Controller>
        <article className="single__containerArticle">
          <div className="single__containerArticleBlock">
            <div className="single__containerArticleBlockThree">
              <div className="single__containerArticleBlockThreeColumn">
                <h2>Client</h2>
                <p>{data.microcmsWorks.detail.client}</p>
              </div>
              <div className="single__containerArticleBlockThreeColumn">
                <h2>Role</h2>
                <p>
                  {roleArry.map((role, roleNum) => (
                    <span key={roleNum}>{role}</span>
                  ))}
                </p>
              </div>
              <div className="single__containerArticleBlockThreeColumn">
                <h2>URL</h2>
                <div className="single__containerArticleBlockThreeColumnUrl">
                  <MagLinks path={`https://${data.microcmsWorks.detail.url}/`} outer>
                    <p>{data.microcmsWorks.detail.url}</p>
                  </MagLinks>
                </div>
              </div>
            </div>
            <div className="single__containerArticleBlockFour">
              <div className="single__containerArticleBlockFourTtl">
                <h2>Credit</h2>
              </div>
              <div className="single__containerArticleBlockFourContent">
              {data.microcmsWorks.credit.map((column, i) => (
                <div key={i} className="single__containerArticleBlockFourContentColumn">
                  <h3>{column.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${column.body}`,
                    }}
                  />
                </div>
              ))}
              </div>
            </div>
          </div>
          <div className="single__containerArticlePc">
            {data.microcmsWorks.imgPC.map((imgsPc, imgsPcNum) => (
              <figure key={imgsPcNum} className="single__containerArticlePcFig">
                <picture>
                  <source media="(max-width: 520px)" srcSet={`${imgsPc.img.url}?w=1000`} />
                  <img src={imgsPc.img.url} alt={data.microcmsWorks.title} />
                </picture>
              </figure>
            ))}
          </div>
          <div className="single__containerArticleSp">
            {data.microcmsWorks.imgSP.map((imgsSp, imgsSpNum) => (
              <figure key={imgsSpNum} className="single__containerArticleSpFig">
                <picture>
                  <source media="(max-width: 520px)" srcSet={`${imgsSp.img.url}?w=300`} />
                  <img src={imgsSp.img.url} alt={data.microcmsWorks.title} />
                </picture>
              </figure>
            ))}
          </div>
        </article>
        <div className="single__containerNext">
        {!nextContains ?
          <NextLink id={`${currentIndex.next.worksId}`} url={`${currentIndex.next.eyecatch.url}`} title={`${currentIndex.next.title}`} launch={`${currentIndex.next.launch}`} />
          :
          <div className="single__containerNextIndex">
            <MagLinks path="/works/">
              <div className="single__containerNextIndexInner">
                <p>To Index</p>
              </div>
            </MagLinks>
          </div>
        }
        </div>
      </section>
    </Layout>
  )
}

export default worksSingle

export const query = graphql`
  query($id: String!) {
    microcmsWorks(id: { eq: $id }) {
      sortIndex
      worksId
      title
      launch
      eyecatch {
        url
      }
      detail {
        client
        role
        url
      }
      credit {
        title
        body
      }
      imgPC {
        img {
          url
        }
      }
      imgSP {
        img {
          url
        }
      }
    }

    allMicrocmsWorks(sort: {fields: publishedAt, order: DESC}) {
      edges {
        next {
          sortIndex
          title
          launch
          eyecatch {
            url
          }
          worksId
        }
      }
    }
  }
`
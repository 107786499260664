import React from "react"
import { Link } from "gatsby"
import { isDesktop } from 'react-device-detect';

import { useMagnetic } from "./hooks/useMagnetic"

export const NextLink = (props) => {
    const {x, y, textX, textY, mouseEnter, mouseMove, mouseLeave} = useMagnetic()

    return (
        <Link 
					to={`/works/${props.id}`} 
					className="single__containerNextIn magnetic"
					onMouseEnter={mouseEnter}
					onMouseMove={mouseMove}
					onMouseLeave={mouseLeave}
        >
            <div className="single__containerNextInFig">
							<figure style={isDesktop ? {transform: `translate(${x}px, ${y}px)`} : null}>
								<picture>
                  <source media="(max-width: 520px)" srcSet={`${props.url}?w=1000`} />
									<img src={props.url} alt={props.title} />
                </picture>
							</figure>
            </div>
            <div className="single__containerNextInTxt">
							<p>Next Case</p>
							<div className="single__containerNextInTxtTtl">
								<h3>{props.title}</h3>
								<span>{props.launch.replace(/\.[0-9][0-9]/, "")}</span>
							</div>
            </div>
            <span 
							className="cmn__mouse"
							style={{transform: `translate(${textX}px, ${textY}px)`}}
            >
							<span>
								<span>View</span>
							</span>
            </span>
        </Link>
    )
}